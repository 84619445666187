import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
// import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import topImage from '../images/partners.png';
import Img from "gatsby-image";
import { clearConfigCache } from "prettier";
import { mergeClasses } from "@material-ui/styles";

const flag = typeof window !== 'undefined';

let jQuery;
if (flag) {
    jQuery = require('jquery');
}

const theme = createTheme({
  palette: {
      primary: {
          main: '#ffffff'
      }
  },
  breakpoints: {
      values: {
          xs: 0,
          sm: 650,
          md: 1333,
          lg: 1920,
          xl: 2000,
      },
    }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
    // gatsbyImageWrapper: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: 'calc(100vw / 4)',
    //     height: 'calc(30vw / 6)'
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     width: 0,
    //     height: 0
    //   }
    // }
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class Partners extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      seo: false
    }

    var url = this.props.location.href
    if (typeof(url) === 'string') {
      if (url.indexOf('staging') !== -1) {
        // console.log(this.state.disabled)
        this.state.disabled = true
        // console.log(this.state.disabled)
      } else {
        this.state.seo = true
      }
      // console.log(this.state.seo)
    }
    // console.log(this.props.data.testFixed)
    // authIP(this)
  }

  async componentDidMount() {
    if (this.state.disabled) {
      var res = await fetch('/api/authIP', {
        method: 'POST'
      }).then((response) => {
        // console.log(response)
        return response.text();
      });
      // console.log(res)
      if (res === 'Y') {
        // console.log(state.state.disabled)
        this.setState({disabled: false});
      }
      // console.log(state.state.disabled)
    }
    // console.log(jQuery('div.gatsby-image-wrapper'))
    jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
    jQuery('div.gatsby-image-wrapper').css('width', '');
    jQuery('div.gatsby-image-wrapper').css('position', '');
    jQuery('div.gatsby-image-wrapper').css('display', '');
  }

  renderImage(image) {
    // console.log(image.node.relativePath.split('/')[1])
    return (
      <table>
        <tr className='image-tr'>
          <Img
            fixed={image.node.childImageSharp.fixed}
            alt={image.node.relativePath.split('/')[1].split('.')[0]}
            className='partners-logo'
            // style={{display: 'table-cell', width: '100%', height: 'auto'}}
          />
        </tr>
        <tr>
          <h2>{image.node.relativePath.split('/')[1].split('.')[0]}</h2>
        </tr>
      </table>
    );
  }

  renderSeo() {
    return (
      <Seo title="Partners">
        <meta
            name="description"
            content="AristoBrain株式会社の人材採用。新卒採用・中途採用広く募集しています。"
        />
        <meta
            name="keywords"
            content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,パートナー"
        />
      </Seo>
    )
  }

  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      // console.log(theme.gatsbyImageWrapper)
      // console.log(this.props.classes.gatsbyImageWrapper)
      // console.log(this.props.data.partnerRogos)
      return (
        <Page url={this.props.location.pathname}>
          <ThemeProvider theme={theme}>
            {this.state.seo && this.renderSeo()}
            <div id='partners-1' className='fade-in-news-image animation-init' style={{backgroundImage: `url(${topImage})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flow-root', padding: '0 5% 10% 0'}}>
              <div style={{margin: 0, padding: 0}}>
                <div className='under-bar-title'>
                  <h1 className='fade-in-news-title'>Partners</h1>
                  <p className='fade-in-about-text'>
                    パートナー
                  </p>
                </div>
                <div className='under-bar-text'>
                  <p className='sharp-under-bar fade-in-about-text'>共同研究機関</p>
                  <div className='partner-logos fade-in-about-text'>
                    {this.props.data.searchRogos.edges.map(image => this.renderImage(image))}
                  </div>
                  <div id='partners-2' className='fade-in-partners-first'>
                    <p className='sharp-under-bar'>主要取引先</p>
                    <div className='partner-logos'>
                      {this.props.data.partnerRogos.edges.map(image => this.renderImage(image))}
                    </div>
                  </div>
                  <div id='partners-3' className='fade-in-partners-second'>
                    <p className='sharp-under-bar'>顧問弁護士</p>
                    <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                      小笠原六川国際総合法律事務所<br />
                      東京弁護士会所属​　伊東 孝
                    </p>
                  </div>
                  <div id='partners-4' className='fade-in-partners-third'>
                    <p className='sharp-under-bar'>顧問税理士</p>
                    <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                        藤原優子税理士事務所<br />
                        東京税理士会所属　藤原 優子
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    searchRogos: allFile(filter: {absolutePath: {regex: "/searchRogo/"}}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 150) {
                ...GatsbyImageSharpFixed
            }
          }
          relativePath
        }
      }
    }
    partnerRogos: allFile(filter: {absolutePath: {regex: "/partnerRogo/"}}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 150) {
                ...GatsbyImageSharpFixed
            }
          }
          relativePath
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(Partners));